import React from 'react';
import Container from "react-bootstrap/Container";

function Footer() {
    return (
        <footer>
            <Container className={"mt-2"}>
                <div className="font-weight-bolder">&#169; CMC Project Solutions</div>
                <div className="font-weight-lighter"><em>100% Certified Woman-Owned</em></div>
                <div className="edwosb">
                    <a href="/edwosb">
                        <img src="/images/logo/edwosb.png" alt="edwosb.png" width={40}/>
                    </a>
                </div>
            </Container>
        </footer>
    );
}

export default Footer;