import React from 'react';
import RouteManager from "../routes/RouteManager";

function Main() {
    return (
        <main>
            <RouteManager/>
        </main>
    );
}

export default Main;