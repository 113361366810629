import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPhoneSquare} from "@fortawesome/free-solid-svg-icons";
import Col from "react-bootstrap/Col";

function ContactPhone() {

    return (
        <Col className="text-nowrap">
            <a href="tel:+18777782337">
                <FontAwesomeIcon icon={faPhoneSquare}/>&nbsp;+1 (877) 778-2337
            </a>
        </Col>
    )
}

export default ContactPhone;