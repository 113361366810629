import React from 'react';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Contact from "../../features/contact/Contact";
import Card from "react-bootstrap/Card";
import ResponsiveEmbed from "react-bootstrap/ResponsiveEmbed";
import {Image} from "react-bootstrap";

import city from "../../theme/city.jpg";

function Home() {

    const divStyle = {
        // width: "100vw",
        // height: "729",
        backgroundImage: `url(${city})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        zIndex: -1000
    };

    return (
        <Container fluid>

            <Row className={"text-center"} style={divStyle}>
                <Col className={"col-lg-10 mx-auto p-lg-5 p-3"}>

                    <Card id={"splash-card"}>
                        <Card.Body>
                            <Row className={"title mb-5"}>
                                <Col>
                                    Project Solutions
                                    <br/>
                                    Made Simple
                                </Col>
                            </Row>
                            <Row className={"logos mx-auto"}>
                                <Col className="text-center">
                                    <img src="/images/logo/o-prtnr-clr-rgb.png" alt="o-prtnr-clr-rgb.png" width={250}/>
                                </Col>
                                <Col className="text-center">
                                    <img src="/images/logo/oracle-primavera.png" alt="oracle-primavera.png" width={200}/>
                                </Col>
                            </Row>
                            <Row className={"advert mt-5"}>
                                <Col>
                                    We'll Beat Any Primavera Software Quote
                                    <br/>
                                    GUARANTEED!
                                </Col>
                            </Row>
                            <Row>
                                <Col className={"link mt-5"}>
                                    <a href={"mailto:info@cmcprojectsolutions.com"}>info@CMCProjectSolutions.com</a>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>

                </Col>

            </Row>

            <Row id={"services"}>

                <Col className={"col-lg-10 mx-auto p-lg-5 p-3"}>

                    <Card id={"service-card"}>

                        <Card.Header>
                            <em>Project Control Services</em>
                        </Card.Header>

                        <Card.Body>

                            <Row>
                                <Col md={12} lg={6}>
                                    <ResponsiveEmbed aspectRatio="16by9">
                                        <video autoPlay={true} playsInline={true} loop={true} muted>
                                            <source src="/videos/scheduling.mp4" type="video/mp4"/>
                                            Your browser does not support the video tag.
                                        </video>
                                    </ResponsiveEmbed>
                                </Col>
                                <Col md={12} lg={6} className="mt-3 mt-sm-3 mt-md-3 mt-lg-0">
                                    <ul className="list-group border-red">
                                        <li className="list-group-item">Project Controls</li>
                                        <li className="list-group-item">Budget & Cost Controls</li>
                                        <li className="list-group-item">Project Scheduling</li>
                                        <li className="list-group-item">Change Management</li>
                                        <li className="list-group-item">Risk Management</li>
                                        <li className="list-group-item">Delay Claims Avoidance</li>
                                        <li className="list-group-item">Document Control</li>
                                        <li className="list-group-item">Project Reporting</li>
                                        <li className="list-group-item">Project Assessments</li>
                                    </ul>
                                </Col>
                            </Row>

                        </Card.Body>

                    </Card>

                </Col>

            </Row>

            <Row>

                <Col className={"col-lg-10 mx-auto p-lg-5 p-3"}>

                    <Card id={"service-card"}>

                        <Card.Header>
                            <em>PMO Support</em>
                        </Card.Header>

                        <Card.Body>

                            <Row>
                                <Col md={12} lg={6}>
                                    <ResponsiveEmbed aspectRatio="16by9">
                                        <video autoPlay={true} playsInline={true} loop={true} muted>
                                            <source src="/videos/consulting.mp4" type="video/mp4"/>
                                            Your browser does not support the video tag.
                                        </video>
                                    </ResponsiveEmbed>
                                </Col>
                                <Col md={12} lg={6} className="mt-3 mt-sm-3 mt-md-3 mt-lg-0">
                                    <ul className="list-group border-green">
                                        <li className="list-group-item">Project Governance</li>
                                        <li className="list-group-item">Enterprise Standards / Process Development</li>
                                        <li className="list-group-item">Resource Capacity Planning</li>
                                        <li className="list-group-item">Integrated Resource Management</li>
                                        <li className="list-group-item">Information Governance / Dashboards / Reports</li>
                                        <li className="list-group-item">Earned Value Management</li>
                                        <li className="list-group-item">Risk Management</li>
                                        <li className="list-group-item">Document Management</li>
                                        <li className="list-group-item">Software Implementation / Integration</li>
                                        <li className="list-group-item">Assessment Services</li>
                                    </ul>
                                </Col>
                            </Row>

                        </Card.Body>

                    </Card>

                </Col>

            </Row>

            <Row>

                <Col className={"col-lg-10 mx-auto p-lg-5 p-3"}>

                    <Card id={"service-card"}>

                        <Card.Header>
                            <em>Staffing</em>
                        </Card.Header>

                        <Card.Body>

                            <Row>
                                <Col md={12} lg={6}>
                                    <ResponsiveEmbed aspectRatio="16by9">
                                        <video autoPlay={true} playsInline={true} loop={true} muted>
                                            <source src="/videos/training.mp4" type="video/mp4"/>
                                            Your browser does not support the video tag.
                                        </video>
                                    </ResponsiveEmbed>
                                </Col>
                                <Col md={12} lg={6} className="mt-3 mt-sm-3 mt-md-3 mt-lg-0">
                                    <ul className="list-group border-blue">
                                        <li className="list-group-item">Project Managers</li>
                                        <li className="list-group-item">Project Schedulers</li>
                                        <li className="list-group-item">Cost Analyst</li>
                                        <li className="list-group-item">Program Managers</li>
                                        <li className="list-group-item">Construction Managers</li>
                                        <li className="list-group-item">Contract Managers</li>
                                    </ul>
                                </Col>
                            </Row>

                        </Card.Body>

                    </Card>

                </Col>

            </Row>

            {/*<Row id={"brochures"} className={"bg-dark"}>*/}

            {/*    <Col className={"col-lg-12 p-lg-3 p-1"}>*/}

            {/*        <Row>*/}
            {/*            <Col md={12}>*/}
            {/*                <iframe src="/files/CMC Company Brochure_12_2_20.pdf" title="Company Brochure" width="100%" height="600px">*/}
            {/*                </iframe>*/}
            {/*            </Col>*/}
            {/*        </Row>*/}

            {/*        <Row>*/}
            {/*            <Col md={12}>*/}
            {/*                <iframe src="/files/CMC Schedule Assurance Brochure.pdf" title="Schedule Assurance Brochure" width="100%" height="600px">*/}
            {/*                </iframe>*/}
            {/*            </Col>*/}
            {/*        </Row>*/}

            {/*    </Col>*/}

            {/*</Row>*/}

            <Row id={"about"}>

                <Col className={"col-lg-10 mx-auto p-lg-5 p-3"}>

                    <Card id={"diversity-card"}>

                        <Card.Header>
                            <em>Diversity Supplier</em>
                        </Card.Header>

                        <Card.Body>

                            <Row className={"text-center mb-5"}>
                                <Col>
                                    <Image src="/images/logo/edwosb.png" alt="edwosb.png" height={"150px"}/>
                                </Col>
                                <Col>
                                    <Image src="/images/logo/cpuc-certified.png" alt="cpuc-certified.png" height={"150px"}/>
                                </Col>
                            </Row>

                            <Card.Text>
                                    CMC Project Solutions is registered as a Economically Disadvantaged Women Owned
                                    Small Business (EDWOSB) with the Federal Small Business Administration as well
                                    as a Women-Owned (WBE) business registered with the California Public Utilities
                                    Commission (CPUC) and Arkansas DOT.
                            </Card.Text>
                            <Card.Text>
                                    With these recognitions, CMC Project Solutions seeks to proudly represent the
                                    industry with inclusive diversity at the forefront of success with our clients.
                            </Card.Text>
                            <Card.Text>
                                    It is our goal to maintain our strong commitment to diversity in the workplace
                                    as well as provide beneficial opportunities to those looking to excel and
                                    advance their career.
                            </Card.Text>
                            <Card.Text>
                                    We here at CMC Project Solutions, Inc. hold this important distinction with
                                    pride knowing the importance of these recognitions. As we continue to build our
                                    relationships with our partners and clients, our fundamental statement of
                                    inclusive diversity remains at the forefront of our success.
                            </Card.Text>

                        </Card.Body>

                    </Card>

                    <Card className={"mt-3"}>
                        <Card.Header>Chrissy Costantino</Card.Header>
                        <Card.Body>
                            <Card.Title>President & CEO</Card.Title>
                            <Row>
                                <Col md={12} lg={6}>
                                    <Card.Img variant="top" src="/images/bio/chrissy.jpg" alt="chrissy.jpg"/>
                                </Col>
                                <Col md={12} lg={6}>
                                    <Card.Text>
                                            Mrs. Costantino is currently the Owner & Operator of CMC Project Solutions,
                                            a California & Arkansas Consulting Company, which is proudly 100%
                                            Woman-Owned and Certified WBE with the California CPUC & Arkansas DOT
                                            as well as a registered Economically Disadvantaged Woman Owned Small
                                            Business (EDWOSB) with the Federal Small Business Association (SBA).
                                    </Card.Text>
                                    <Card.Text>
                                            Her career began as a technically-adapted Administrative Assistant with
                                            state-of-the-art knowledge of Oracle's Primavera P6 and experience in
                                            developing client solutions within their complex environments.
                                    </Card.Text>
                                    <Card.Text>
                                            As an Oracle Specialist Engineer for Primavera P6 EPPM, Chrissy Costantino
                                            is a professional with outstanding leadership experience of over 20 years to
                                            lead and contribute to the development and growth of the company's
                                            performance. A motivator with impressive analytical and evaluation skills,
                                            and rapid ability to absorb, develop and act upon new conceptions,
                                            strategies, and business ideas. A thorough well-organized worker and
                                            dependable problem solver who remains innovative and communicates
                                            effectively, her goal is to lead by example by providing the best in class
                                            consulting services and achieve her dream of running a successful
                                            organization.
                                        <Card.Img
                                            src="/images/logo/oracle-badge.png"
                                            alt="oracle-badge.png"
                                            style={{width: "50%", marginLeft: "25%"}}
                                        />
                                    </Card.Text>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>

                    <Card className={"mt-3"}>
                        <Card.Header>Michael Letzeiser</Card.Header>
                        <Card.Body>
                            <Card.Title>Director of Business Operations</Card.Title>
                            <Row>
                                <Col md={12} lg={6}>
                                    <Card.Img variant="top" src="/images/bio/mike-letzeiser.png"
                                              alt="mike-letzeiser.png"/>
                                </Col>
                                <Col md={12} lg={6}>
                                    <Card.Text>
                                            Michael Letzeiser is the Director of Business Operations at CMC Project
                                            Solutions where his responsibilities include managing all direct accounts
                                            and
                                            employees within the company.
                                    </Card.Text>
                                    <Card.Text>
                                            Mr. Letzeiser holds a Masters Degree in Health and
                                            Wellness where he understands the importance of maintaining not just a
                                            healthy
                                            body but also a healthy company which creates long term success for our
                                            clients.
                                        <Card.Img
                                            src="/images/logo/oracle-badge.png"
                                            alt="oracle-badge.png"
                                            style={{width: "50%", marginLeft: "25%"}}
                                        />
                                    </Card.Text>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>

                    <Card className={"mt-3"}>
                        <Card.Header>Zoe Rodecker</Card.Header>
                        <Card.Body>
                            <Card.Title>Executive Assistant</Card.Title>
                            <Row>
                                <Col md={12} lg={6}>
                                    <Card.Img variant="top" src="/images/bio/zoe-rodecker.jpg" alt="zoe-rodecker.jpg"/>
                                </Col>
                                <Col md={12} lg={6}>
                                    <Card.Text>
                                            Mrs. Rodecker is currently the Executive Assistant to the team at CMC
                                            Project Solutions, Inc. bringing over 20 years of experience for multi-
                                            administration processes ranging from accounting to payroll with direct
                                            responsibilities for maintaining positive customer/client relationships.
                                    </Card.Text>
                                    <Card.Text>
                                            Her diverse background has helped her manage international organizations
                                            ranging from the financial and building sectors to local government agencies
                                            utilizing her skills and knowledge to manage and maintain successful
                                            customer portfolios.
                                    </Card.Text>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>

                    <Card className={"mt-3"}>
                        <Card.Header>Mike Scrimsher</Card.Header>
                        <Card.Body>
                            <Card.Title>Project Controls Manager</Card.Title>
                            <Row>
                                <Col md={12} lg={6}>
                                    <Card.Img variant="top" src="/images/bio/mike-scrimsher.png"
                                              alt="mike-scrimsher.png"/>
                                </Col>
                                <Col md={12} lg={6}>
                                    <Card.Text>
                                            Mr. Scrimsher currently holds a BS in Civil Engineering from University of
                                            California at Berkeley and with over 35 direct years working in the
                                            construction Industry, he is an expert in several key industries performing
                                            work within the following sectors, but not limited to: Light Rail and Linear
                                            Projects, Transmission, Distribution & Substation, Heavy Civil, Excavation,
                                            Grading, Site Work & Garden Style, Mix-Use, Multi Family, Vertical
                                            Construction & Self Performed Concrete, Public Works, Private, Highway
                                            Civil, Army Corp, Far Spec, FTA, Capital Projects, Due Diligence,
                                            Inspection, MEP Coordinator, Wet Pipelines, Methane Gas Landfills, Contract
                                            Negotiation, Contract Administration, Design, RFI, Submittals, CPM
                                            Scheduling, Cost Estimating (CSI Divisions 1-33), GMP, Unit Cost, Lump Sum,
                                            Design Build, Hard Dollar Bid, Conceptual DD-CD, Cost Control, Writing
                                            scopes of work for Buy-outs, P & L Responsibility & Project Budgeting.
                                    </Card.Text>
                                    <Card.Text>
                                            We here at CMC Project Solutions, Inc. are proud to have his experience,
                                            skills and knowledge on our team to assist in guiding your projects to
                                            complete success.
                                    </Card.Text>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>

                </Col>

            </Row>

            <Row id={"contact"} className={"bg-dark"}>

                <Col className={"col-lg-10 mx-auto p-lg-5 p-3"}>

                    <Contact/>

                </Col>

            </Row>

        </Container>
    )

}

export default Home;