import React from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';

import withTracker from '../withTracker';

import Home from "../views/home/Home";
// import About from "../views/about/About";
// import Contact from "../features/contact/Contact";
//
// import Alerts from "./Alerts";
// import Scheduling from "../views/services/Scheduling";
// import Consulting from "../views/services/Consulting";
// import Training from "../views/services/Training";
// import Hosting from "../views/services/Hosting";
// import Brochures from "../views/services/Brochures";
// import Diversity from "../views/about/Diversity";
// import Demo from "../views/demo/Demo";

function RouteManager() {
    return (
        <Router>
            <Switch>
                <Route exact path="/" component={withTracker(Home)}/>

                {/*<Route path="/about" component={withTracker(About)}/>*/}
                {/*<Route path="/diversity" component={withTracker(Diversity)}/>*/}
                {/*<Route path="/contact" component={withTracker(Contact)}/>*/}

                {/*<Route path="/services/scheduling" component={withTracker(Scheduling)}/>*/}
                {/*<Route path="/services/consulting" component={withTracker(Consulting)} exact/>*/}
                {/*<Route path="/services/training" component={withTracker(Training)} exact/>*/}
                {/*<Route path="/services/hosting" component={withTracker(Hosting)}/>*/}
                {/*<Route path="/services/brochures" component={withTracker(Brochures)}/>*/}

                {/*<Route path="/demo" component={Demo}/>*/}

                {/*<Route path="/alerts" component={Alerts}/>*/}

            </Switch>
        </Router>
    );
}

export default RouteManager;