import React from "react";
import Row from "react-bootstrap/Row";
import ContactPhone from "./ContactPhone";
import ContactEmail from "./ContactEmail";

function Contact(props) {

    const {id} = props;

    return (
        <Row id={id} className="justify-content-end">
            <ContactPhone/>
            <ContactEmail/>
        </Row>
    )
}

export default Contact;