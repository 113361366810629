import React from "react";
import Navbar from "react-bootstrap/Navbar";
import Contact from "./Contact";
import Menu from "./Menu";
import Container from "react-bootstrap/Container";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEnvelopeSquare, faPenSquare, faPhoneSquare} from "@fortawesome/free-solid-svg-icons";
import {faFacebookSquare, faLinkedin, faTwitterSquare} from "@fortawesome/free-brands-svg-icons";

function Header() {

    return (
        <header>
            <Navbar fixed="top" className="bg-white border-bottom border-secondary">
                <Container fluid>
                    <Navbar.Brand id="brand" href="/">
                        <img src="/images/logo/logo.png" alt="logo.png" width="225px"/>
                    </Navbar.Brand>
                    <div className="d-flex flex-column">
                        <Contact id={"contact-inline"}/>
                        <Menu id={"menu-inline"}/>
                    </div>
                    {/*<div className="d-flex flex-row">*/}
                    {/*    <div className={"mr-2"}>*/}
                    {/*        <a href="#contact">*/}
                    {/*            <FontAwesomeIcon icon={faPenSquare}/>*/}
                    {/*        </a>*/}
                    {/*    </div>*/}
                    {/*    <div className={"mr-2"}>*/}
                    {/*        <a href="tel:+18777782337"*/}
                    {/*           target="_blank" rel="noopener noreferrer">*/}
                    {/*            <FontAwesomeIcon icon={faPhoneSquare}/>*/}
                    {/*        </a>*/}
                    {/*    </div>*/}
                    {/*    <div className={"mr-2"}>*/}
                    {/*        <a href="mailto:info@cmcprojectsolutions.com">*/}
                    {/*            <FontAwesomeIcon icon={faEnvelopeSquare}/>*/}
                    {/*        </a>*/}
                    {/*    </div>*/}
                    {/*    <div className={"mr-2"}>*/}
                    {/*        <a href="https://www.linkedin.com/company/cmc-project-solutions-inc/"*/}
                    {/*           target="_blank" rel="noopener noreferrer">*/}
                    {/*            <FontAwesomeIcon icon={faLinkedin}/>*/}
                    {/*        </a>*/}
                    {/*    </div>*/}
                    {/*    <div className={"mr-2"}>*/}
                    {/*        <a href="https://www.facebook.com/CMC-Project-Solutions-Inc-111256904049929/"*/}
                    {/*           target="_blank" rel="noopener noreferrer">*/}
                    {/*            <FontAwesomeIcon icon={faFacebookSquare}/>*/}
                    {/*        </a>*/}
                    {/*    </div>*/}
                    {/*    <div className={"mr-2"}>*/}
                    {/*        <a href="https://twitter.com/cmc_inc"*/}
                    {/*           target="_blank" rel="noopener noreferrer">*/}
                    {/*            <FontAwesomeIcon icon={faTwitterSquare}/>*/}
                    {/*        </a>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    <div className={"d-flex flex-column"}>
                        <div className={"d-flex flex-row"}>
                            <div className={"mr-3"}>
                                <a href="#contact">
                                    <FontAwesomeIcon icon={faPenSquare} size={"lg"}/>
                                </a>
                            </div>
                            <div className={"mr-3"}>
                                <a href="tel:+18777782337"
                                   target="_blank" rel="noopener noreferrer">
                                    <FontAwesomeIcon icon={faPhoneSquare} size={"lg"}/>
                                </a>
                            </div>
                            <div className={"mr-3"}>
                                <a href="mailto:info@cmcprojectsolutions.com">
                                    <FontAwesomeIcon icon={faEnvelopeSquare} size={"lg"}/>
                                </a>
                            </div>
                        </div>
                        <div className={"d-flex flex-row"}>
                            <div className={"mr-3"}>
                                <a href="https://www.linkedin.com/company/cmc-project-solutions-inc/"
                                   target="_blank" rel="noopener noreferrer">
                                    <FontAwesomeIcon icon={faLinkedin} size={"lg"}/>
                                </a>
                            </div>
                            <div className={"mr-3"}>
                                <a href="https://www.facebook.com/CMC-Project-Solutions-Inc-111256904049929/"
                                   target="_blank" rel="noopener noreferrer">
                                    <FontAwesomeIcon icon={faFacebookSquare} size={"lg"}/>
                                </a>
                            </div>
                            <div className={"mr-3"}>
                                <a href="https://twitter.com/cmc_inc"
                                   target="_blank" rel="noopener noreferrer">
                                    <FontAwesomeIcon icon={faTwitterSquare} size={"lg"}/>
                                </a>
                            </div>
                        </div>
                    </div>

                </Container>
            </Navbar>
        </header>
    )

}

export default Header;