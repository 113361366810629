import {createSlice} from '@reduxjs/toolkit';
import axios from 'axios';
import {toastr} from 'react-redux-toastr';

export const contactSlice = createSlice({
    name: 'contact',
    initialState: {
        value: {},
    },
    reducers: {
        handleSuccess: (state, action) => {
            console.log('MESSAGE SENT %O', action);
        },
        handleError: (state, action) => {
            console.log('MESSAGE ERROR %O', action);
        }
    },
});

export const {handleSuccess, handleError} = contactSlice.actions;

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched
// export const send = message => dispatch => {
export const send = message => {
    return async dispatch => {
        try {
            console.log('POSTING MESSAGE', message);
            const response = await axios.post('/mail', message);
            console.log('MESSAGE SUCCESS', response);
            toastr.success(response.data.result);
            dispatch(handleSuccess(response.data.result));
        }
        catch(error) {
            console.log('MESSAGE ERROR %O', error);
            toastr.error(error.message);
            dispatch(handleError(error.message));
        }
    }
};

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
// export const selectCount = state => state.counter.value;

export default contactSlice.reducer;
