import React, {useState} from 'react';
import {connect, useDispatch} from 'react-redux'
import {Button, Form} from 'react-bootstrap';
import {send} from './contactSlice';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";

const mapDispatch = {send};

export function Contact() {

    const dispatch = useDispatch();

    const [email, setEmail] = useState('')
    const [subject, setSubject] = useState('')
    const [message, setMessage] = useState('')

    const onChangeEmail = (e) => setEmail(e.target.value);
    const onChangeSubject = (e) => setSubject(e.target.value);
    const onChangeMessage = (e) => setMessage(e.target.value);

    const onSubmit = (e) => {
        e.preventDefault();
        if (!email.trim() || !subject.trim() || !message.trim()) {
            return;
        }
        dispatch(send({from: email, subject: subject, message: message}));
    }

    return (
        <Container fluid>
            <Row id={"contact-card"}>
                <Col className="p-0 mb-3">
                    <Card>
                        <Card.Header className="bg-light">Contact Us</Card.Header>
                        <Card.Body className="bg-light-trans">
                            <Form onSubmit={onSubmit}>
                                <Form.Label>Please contact us for more information:</Form.Label>
                                <Form.Group controlId="formBasicEmail">
                                    <Form.Control type="email" placeholder="Enter Email" onChange={onChangeEmail}
                                                  required/>
                                    <Form.Text className="text-muted">
                                        We will never share your email address with anyone.
                                    </Form.Text>
                                </Form.Group>
                                <Form.Group controlId="formBasicSubject">
                                    <Form.Control type="text" placeholder="Enter Subject" onChange={onChangeSubject}
                                                  required/>
                                </Form.Group>
                                <Form.Group controlId="formBasicMessage">
                                    <Form.Control as="textarea" rows="3" placeholder="Enter Message"
                                                  onChange={onChangeMessage} required/>
                                </Form.Group>
                                <p className="text-center">
                                    <Button variant="dark" type="submit">Submit</Button>
                                </p>
                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );

}

export default connect(null, mapDispatch)(Contact);