import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEnvelopeSquare} from "@fortawesome/free-solid-svg-icons";
import Col from "react-bootstrap/Col";

function ContactEmail() {

    return (
        <Col className="text-nowrap">
            <a href="mailto:info@cmcprojectsolutions.com">
                <FontAwesomeIcon icon={faEnvelopeSquare}/>&nbsp;info@cmcprojectsolutions.com
            </a>
        </Col>
    )
}

export default ContactEmail;