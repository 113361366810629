import {configureStore} from '@reduxjs/toolkit';
import contactReducer from '../features/contact/contactSlice';
import {reducer as toastrReducer} from 'react-redux-toastr';

export default configureStore({
    reducer: {
        contact: contactReducer,
        toastr: toastrReducer
    },
});
