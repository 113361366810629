import React, {useEffect} from 'react';
import {BrowserRouter as Router} from 'react-router-dom';
import styles from './App.scss';
import Container from "react-bootstrap/Container";
import Header from "./layout/Header";
import Main from "./layout/Main";
import Row from "react-bootstrap/Row";
import Footer from "./layout/Footer";
import ReactGA from 'react-ga';

ReactGA.initialize('UA-185996253-1');

function App() {

    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);

    return (
        <div className={styles.app}>
            <Router>
                <Container fluid className="vh-100 d-flex flex-column">
                    <Row id="header" className="d-block">
                        <Header/>
                    </Row>
                    <Row id="main" className="d-block">
                        <Main/>
                    </Row>
                    <Row id="footer" className="d-block">
                        <Footer/>
                    </Row>
                </Container>
            </Router>
        </div>
    )

}

export default App;
