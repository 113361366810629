import React from "react";
import Nav from "react-bootstrap/Nav";

function Menu(props) {

    const {id} = props;

    return (
        <Nav id={id} className="justify-content-center font-weight-bold">

            <Nav.Link href={"/"} className={"flex-fill"}>Home</Nav.Link>

            <Nav.Link href={"/#services"} className={"flex-fill"}>Services</Nav.Link>

            {/*<Nav.Link href="/#brochures" className="flex-fill">Brochures</Nav.Link>*/}

            <Nav.Link href="/#about" className="flex-fill">About</Nav.Link>

            <Nav.Link href="/#contact" className="flex-fill">Contact</Nav.Link>

            {/*<NavDropdown id="services-dropdown" title="Services" className="flex-fill">*/}
            {/*    <NavDropdown.Item href="/services/scheduling">Scheduling</NavDropdown.Item>*/}
            {/*    <NavDropdown.Item href="/services/consulting">Consulting</NavDropdown.Item>*/}
            {/*    <NavDropdown.Item href="/services/training">Training</NavDropdown.Item>*/}
            {/*    <NavDropdown.Item href="/services/hosting">Hosting</NavDropdown.Item>*/}
            {/*    <NavDropdown.Divider/>*/}
            {/*    <NavDropdown.Item href="/services/brochures">Brochures</NavDropdown.Item>*/}
            {/*    /!*<NavDropdown.Item href="/software">Software</NavDropdown.Item>*!/*/}
            {/*</NavDropdown>*/}


            {/*<Nav.Link href="/contact" className="flex-fill">Contact</Nav.Link>*/}
            {/*<Nav.Link href="/diversity" className="flex-fill">Diversity Supplier</Nav.Link>*/}
        </Nav>
    )

}

export default Menu;